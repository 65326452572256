<template>
  <main>
    <make-banner height="420"></make-banner>
    <article class="make-why">
      <div class="promake-title">为什么选择大雨</div>
      <div class="why-list mt30 core row wrap">
        <div class="why-item make-item column-center" v-for="(item, index) in why_select" :key="index">
          <el-image class="mb10 img" :src="item.image" lazy fit="cover"></el-image>
          <p class="item-title normal xxl mb10">{{ item.title }}</p>
          <p class="item-subtitle xxs warn">{{ item.sub_title }}</p>
        </div>
      </div>
    </article>
    <article class="make-flow">
      <div class="promake-title">定制流程</div>
      <div class="flow-list row wrap core">
        <div class="flow-item make-item column-center" v-for="(item, index) in custom_process" :key="index">
          <el-image class="mb10 img" :src="item.image" lazy fit="cover"></el-image>
          <p class="item-title lighter xl mb10">{{ item.title }}</p>
        </div>
      </div>
    </article>
    <article class="make-pro">
      <div class="promake-title white">定制工艺</div>
      <div class="pr pro-banner">
        <swiper :options="swiperOption" ref="mySwiper" class="core swiper">
          <!-- 轮播的图片 -->
          <swiper-slide class="banner-item" v-for="item in 3" :key="item">
            <div class="item-t">
              <p class="title xxl bold">丝印</p>
              <p class="subtitle muted md mb30">Silkscreen</p>
            </div>
            <div class="item-b line2">
              <p class="nr muted">
                丝印是通过刮板的挤压，使油墨通过图文部分的网孔转移到承印物
                上，形成与原稿一样的图文。
                丝印在各种不同材质产品上均可使用，适用于各种
                丝印在各种不同材质产品上均可使用，适
                丝印在各种不同材质产品上均可使用，适颜色的定制，墨层厚实，立体感强，质感丰富...
              </p>
              <button class="item-btn md white" type="primary">立即定制</button>
            </div>
          </swiper-slide>

          <!-- 左右导航栏 -->
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </article>
    <article class="make-case core">
      <div class="promake-title">经典案例</div>
      <make-storeList width="378" height="495" ph="35" pv="20" titleSize="22" :join="false" :priceSize="22"
        :oldpriceShow="false"></make-storeList>
      <button type="primary" class="white md">更多经典案例 ></button>
    </article>
  </main>
</template>
<script>
import MakeStoreList from "@/components/MakeStoreList";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "promake",
  props: {},
  components: {
    swiper,
    swiperSlide,
    "make-storeList": MakeStoreList,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 25,
        loop: true, // 设置图片循环
        autoplay: {
          //自动播放
          delay: 10000,
          // disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        centeredSlides: true,
        speed: 1000, //切换速度
        //左右导航栏
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      why_select: [], //选择大雨原因
      custom_process: [], //定制流程
    };
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * @description 获取产品定制
     * @returns void
     */
    getCustomContent() {
      this.$api.getCustomContentApi().then((res) => {
        let { code, data } = res;
        if (code == 1) {
          this.why_select = data.why_select;
          this.custom_process = data.custom_process;
        }
      });
    },
  },
  created() {
    this.getCustomContent();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.promake-title {
  color: $-color-normal;
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 16px;
  position: relative;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    width: 41px;
    height: 4px;
    background: #cccccc;
    border-radius: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.white {
    color: $-color-white;

    &::after {
      background-color: $-color-white;
    }
  }
}

.make-item {
  background-image: url("~@/assets/makewhy-bg.jpg");
  background-size: cover;

  .img {
    width: auto;
    height: 70px;
  }
}

.make-why {
  background: #f9f9f9;
  padding: 95px 91px;

  .why-item {
    width: 385px;
    height: 255px;
    margin-right: 22.5px;
    margin-top: 20px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(-n + 3) {
      margin-top: 0;
    }
  }
}

.make-flow {
  padding: 91px 0 106px;

  .flow-list {
    padding: 0 17px;
    margin-top: 42px;

    .flow-item {
      width: 280px;
      height: 187px;
      background: $-color-white;
      border-radius: 20px;
      overflow: hidden;
      margin-right: 15px;
      margin-top: 15px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-child(-n + 4) {
        margin-top: 0;
      }
    }
  }
}

.make-pro {
  width: 100%;
  padding: 102px 0 90px;
  background-position: center;
  background-image: url("~@/assets/makepro-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  .promake-title {
    margin-bottom: 58px;
  }

  .pro-banner {
    max-width: 1436px;
    min-width: 1200px;
    margin: 0 auto;

    .swiper {
      position: initial;
      width: 1097px;
      height: 605px;

      ::v-deep .swiper-wrapper {
        align-items: flex-end;
      }

      .banner-item {
        width: 360px;
        height: 482px;
        background-color: $-color-white;
        text-align: center;
        position: relative;
        transition: all 0.6s;

        .item-t {
          padding-top: 100px;
          transition: inherit;

          .title {
            transition: inherit;
            color: #555555;
          }

          .subtitle {}
        }

        .item-b {
          padding: 0 52px;
          -webkit-line-clamp: 7;
          line-height: 30px;
          transition: inherit;

          .item-btn {
            background-color: transparent;
            width: 171px;
            height: 0px;
            position: absolute;
            bottom: 62px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            transition: inherit;
          }
        }
      }

      .swiper-slide-active {
        // .banner-item:hover {
        height: 605px;

        .item-t {
          padding: 70px 0;
          background-color: $-color-primary;
          color: $-color-white;

          .title,
          .subtitle {
            color: inherit;
          }

          .subtitle {
            margin-bottom: 0;
          }
        }

        .item-b {
          padding-top: 45px;

          .item-btn {
            display: block;
            height: 60px;
            background-color: $-color-primary;
          }
        }
      }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next,
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      background-image: url(~@/assets/icon/icon-arrow2.png);
      width: 60px;
      height: 60px;
      background-size: 100%;
      right: 0;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      transform: rotate(-180deg);
      left: 0;
    }
  }
}

.make-case {
  padding: 103px 0 62px;

  ::v-deep .store-list {
    padding: 0 13px 50px;
    margin-top: 43px !important;

    .store-item .item-pro {
      margin-bottom: 50px;
    }
  }

  button {
    width: 198px;
    height: 61px;
    margin: 0 auto;
    display: block;
  }
}
</style>